// Include Bootstrap
import * as bootstrap from "bootstrap";

// Include jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;

// Include jquery ui widgets as needed
import "jquery-ui/ui/widgets/autocomplete";

// Include masonry
import Masonry from "masonry-layout";
window.Masonry = Masonry;

// NProgress
import NProgress from "nprogress";
window.NProgress = NProgress;
NProgress.configure({
	showSpinner: false,
});
NProgress.start();
NProgress.set(0.4);
var interval = setInterval(function () {
	NProgress.inc();
}, 1000);
window.addEventListener("load", function () {
	NProgress.done();
	clearInterval(interval);
});

// Import all images
function requireAll(r) {
	r.keys().forEach(r);
}
requireAll(require.context("../images/", true));

/******************************** Global functions *************************************/

// Dom ready function
window.domReady = function (callback) {
	document.readyState === "interactive" || document.readyState === "complete"
		? callback()
		: document.addEventListener("DOMContentLoaded", callback);
};

// Get cookie function
window.getCookie = function (cname) {
	var name = cname + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "undefined";
};

// Set cookie function
window.setCookie = function (cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	return cvalue;
};

// Get json data
window.getJsonData = function (id, clear) {
	var $el = document.getElementById(id);
	if ($el) {
		var json = $el.textContent || $el.innerText;
		var data = JSON.parse(json);
		if (clear) {
			$el.innerHTML = "";
		}
		return data;
	} else {
		return undefined;
	}
};

/******************************** Theme functions *************************************/

// Sticky navbar
const $headerNav = document.getElementById("header-nav");
if ($headerNav) {
	let $headerTop = document.getElementById("header-top");
	let $headerMain = document.getElementById("header-main");
	let $siteLogo = $headerNav.querySelector(".site-logo");
	function stickyNav() {
		if (window.scrollY >= $headerTop.offsetHeight + $headerMain.offsetHeight && window.innerWidth <= 768) {
			$headerNav.classList.add("fixed-top");
			$siteLogo.classList.add("d-inline-block");
			document.body.style.paddingTop = $headerNav.offsetHeight + "px";
		} else {
			$headerNav.classList.remove("fixed-top");
			$siteLogo.classList.remove("d-inline-block");
			document.body.style.paddingTop = "0";
		}
	}
	window.addEventListener("scroll", () => {
		stickyNav();
	});
	window.addEventListener("resize", () => {
		stickyNav();
	});
	domReady(() => {
		stickyNav();
	});
}

// Back to top
const $backToTop = document.getElementById("back-to-top");
if ($backToTop) {
	$backToTop.addEventListener("click", (e) => {
		e.preventDefault();
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	});
	window.addEventListener("scroll", () => {
		if (window.scrollY >= 400) {
			$backToTop.classList.remove("d-none");
			$backToTop.classList.add("d-block");
		} else {
			$backToTop.classList.remove("d-block");
			$backToTop.classList.add("d-none");
		}
	});
	/*
	function moveBackToTop() {
		if (window.__lc) {
			var $chatWidget = document.getElementById("chat-widget-container");
			if ($chatWidget) {
				$backToTop.style.bottom = "20px";
				$backToTop.style.left = "20px";
				$backToTop.style.right = "auto";
			} else {
				setTimeout(moveBackToTop, 100);
			}
		}
	}
	domReady(() => {
		moveBackToTop();
	});
	*/
}

// Tooltips
const $toolTips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
if ($toolTips) {
	$toolTips.forEach(($toolTip) => {
		new bootstrap.Tooltip($toolTip);
	});
}

// Form validation
const $forms = document.querySelectorAll(".needs-validation");
if ($forms) {
	$forms.forEach(($form) => {
		$form.addEventListener(
			"submit",
			(e) => {
				if (!$form.checkValidity()) {
					e.preventDefault();
					e.stopPropagation();
				}
				$form.classList.add("was-validated");
			},
			false
		);
	});
}

// Text resizing
const $textSizer = document.getElementById("text-sizer");
if ($textSizer) {
	$textSizer.querySelector(".size-1").addEventListener("click", (e) => {
		e.preventDefault();
		resizeText(1);
		setCookie("text_size", 1, 30);
	});
	$textSizer.querySelector(".size-2").addEventListener("click", (e) => {
		e.preventDefault();
		resizeText(2);
		setCookie("text_size", 2, 30);
	});
	$textSizer.querySelector(".size-3").addEventListener("click", (e) => {
		e.preventDefault();
		resizeText(3);
		setCookie("text_size", 3, 30);
	});
	function resizeText(size) {
		if (size != "undefined") {
			size = Number(size);
			$textSizer.querySelectorAll("a").forEach((el) => {
				el.classList.remove("current-size");
			});
			$textSizer.querySelector("a.size-" + size).classList.add("current-size");
			var $body = document.querySelector("body");
			switch (size) {
				case 1:
					$body.classList.remove("big");
					$body.classList.add("small");
					break;
				case 2:
					$body.classList.remove("small", "big");
					break;
				case 3:
					$body.classList.remove("small");
					$body.classList.add("big");
					break;
				default:
					$body.classList.remove("small", "big");
					break;
			}
		}
	}
	resizeText(getCookie("text_size"));
}

// Masonry layout
const $masonryGrid = document.querySelector(".masonry-grid");
if ($masonryGrid) {
	let msnry = new Masonry($masonryGrid, { percentPosition: true });
	window.addEventListener("beforeprint", () => {
		msnry.destroy();
	});
	window.addEventListener("afterprint", () => {
		msnry = new Masonry($masonryGrid, { percentPosition: true });
	});
}

// Find help links (nav bar)
/*
const $findHelpLinks = document.querySelectorAll(".find-help-link");
if ($findHelpLinks) {
	$findHelpLinks.forEach(($link) => {
		$link.addEventListener("click", (e) => {
			e.preventDefault();
			forwardHelpUrl($link.dataset.external, $link.href);
		});
	});
}
*/

// Find help select menu
const $findHelpSelect = document.querySelector("#find-help-select");
if ($findHelpSelect) {
	$findHelpSelect.addEventListener("change", (e) => {
		var $selectedOption = $findHelpSelect.options[$findHelpSelect.selectedIndex];
		if ($selectedOption.value) {
			forwardHelpUrl($selectedOption.dataset.external, $selectedOption.value, $selectedOption.text);
			$findHelpSelect.value = "";
		}
	});
}

// Find help map
const $findHelpMap = document.querySelector("#col-map");
if ($findHelpMap) {
	let map = new FlaMap(map_cfg);
	map.drawOnDomReady("col-map");
	map.on("click", (e, sid, map) => {
		e.preventDefault();
		var mapData = map.mapConfig.map_data;
		if (sid in mapData) {
			var stateName = mapData[sid].name;
			var siteData = getJsonData("fla-sites", false);
			var lang = siteData.lang;
			var stateSites = siteData.stateSites;
			if (stateName in stateSites) {
				forwardHelpUrl(stateSites[stateName].isExternal, stateSites[stateName].urls[lang], stateSites[stateName].name);
			}
		}
	});
}

// Find help forward
window.forwardHelpUrl = function (isExternal, href, name) {
	if (isExternal == "true" || isExternal == true) {
		var $modal = new bootstrap.Modal("#findHelpModal");
		var $modalElement = document.querySelector("#findHelpModal");
		if ($modal) {
			$modalElement.querySelector(".modal-body").innerHTML = $modalElement
				.querySelector(".d-none")
				.innerHTML.replace(/\[\[name\]\]/g, "<strong>" + name + "</strong>")
				.replace(/\[\[url\]\]/g, '<strong><a href="' + href + '">' + href + "</a></strong>");
			$modalElement.querySelector(".btn-primary").addEventListener("click", () => {
				window.location.href = href;
				$modal.hide();
			});
			$modal.show();
		}
	} else {
		window.location.href = href;
	}
};

// Item cards (resources and orgs)
const $itemLists = document.querySelectorAll(".item-list");
if ($itemLists) {
	$itemLists.forEach(($list) => {
		$list.querySelectorAll(".card").forEach((el) => {
			el.addEventListener("click", (e) => {
				e.preventDefault();
				location.href = el.dataset.url;
			});
		});
		$list.querySelectorAll("a").forEach((el) => {
			el.addEventListener("click", (e) => {
				e.stopPropagation();
			});
		});
	});
}

// FAQ Accordions
const $faqAccordionItems = document.querySelectorAll(".faq .accordion .accordion-collapse");
if ($faqAccordionItems) {
	$faqAccordionItems.forEach(($item) => {
		$item.addEventListener("shown.bs.collapse", () => {
			var $parent = $item.parentNode;
			if ($parent.getBoundingClientRect().top < 0) {
				var scrollOffset = $parent.offsetTop;
				window.scroll({
					top: scrollOffset,
					left: 0,
					behavior: "smooth",
				});
			}
		});
	});
}

/******************************** Event tracking *************************************/

/*
	ga:dimension1 - Locale - locale
	ga:dimension2 - CountyID - county_id
	ga:dimension3 - County Name - county_name
	ga:dimension4 - ResourceID - resource_id
	ga:dimension5 - Resource Name - resource_name
	ga:dimension6 - Resource Author - resource_author
	ga:dimension7 - Date Modified - date_modified
	ga:dimension8 - OrganizationID - organization_id
	ga:dimension9 - Organization Name - organization_name
	ga:dimension10 - Zip Code - zip_code
	ga:dimension11 - CityID - city_id
	ga:dimension12 - City Name - city_name
*/

// Rating widget
const $ratingWidget = document.getElementById("rating-widget");
if ($ratingWidget) {
	$ratingWidget.querySelectorAll("a").forEach((el) => {
		el.addEventListener("click", (e) => {
			e.preventDefault();
			/*
			if (typeof _gaq != "undefined") {
				_gaq.push(["_trackEvent", "Rating", el.dataset.rating, el.pathname]);
			}
			if (typeof ga != "undefined") {
				ga("send", "event", {
					eventCategory: "Rating",
					eventAction: el.dataset.rating,
					eventLabel: el.pathname,
					eventValue: el.dataset.value,
					dimension1: el.dataset.locale,
					dimension4: el.dataset.id,
				});
			}
			*/
			if (typeof gtag != "undefined") {
				gtag("event", "rating_set", {
					rating_text: el.dataset.rating,
					rating_value: el.dataset.value,
					resource_url: el.pathname,
					resource_id: el.dataset.id,
					locale: el.dataset.locale,
				});
			}
			alert($ratingWidget.dataset.message);
		});
	});
}

// TODO: Rewrite in plain js
// Event tracking
$(function () {
	/*
	dimension1 = Language/LocaleID - locale
	dimension2 = CountyID - county_id
	dimension3 - CountyName - county_name
	dimension4 = ResourceID - resource_id
	*/
	if (typeof gtag != "undefined") {
		/*
		if (typeof gaDimensions == "undefined") {
			var gaDimensions = {};
			gaDimensions["dimension2"] = "UNKNOWN";
			gaDimensions["dimension3"] = "UNKNOWN";
		}
		*/
		if (typeof ga4Dimensions == "undefined") {
			var ga4Dimensions = {};
			ga4Dimensions["county_id"] = "UNKNOWN";
			ga4Dimensions["county_name"] = "UNKNOWN";
		}
		$("a.download-link").on("click", function (e) {
			var $this = $(this);
			var href = $this.attr("href").split("?")[0];
			var fileType = $this.attr("filetype");
			var localeId = $this.attr("localeid").toUpperCase();
			var resourceId = $this.attr("resourceid");
			/*
			if (typeof _gaq != "undefined") {
				_gaq.push(["_trackEvent", "Downloads", fileType, href]);
			}
			if (typeof ga != "undefined") {
				ga("send", "event", {
					eventCategory: "Downloads",
					eventAction: fileType,
					eventLabel: href,
					dimension1: localeId,
					dimension2: gaDimensions["dimension2"],
					dimension3: gaDimensions["dimension3"],
					dimension4: resourceId,
				});
			}
			*/
			// This is also collected by GA4, how do we attach to it and include locale, county info and resource id without sending here?
			if (typeof gtag != "undefined") {
				gtag("event", "file_download", {
					file_extension: fileType,
					link_url: href,
					locale: localeId,
					county_id: ga4Dimensions["county_id"],
					county_name: ga4Dimensions["county_name"],
					resource_id: resourceId,
				});
			}
			setTimeout(function () {
				location.href = href;
			}, 200);
			return false;
		});
		$("a.external-link").on("click", function (e) {
			var $this = $(this);
			var href = $this.attr("href").split("?")[0];
			var target = $this.attr("target") ? $this.attr("target") : "";
			var localeId = $this.attr("localeid").toUpperCase();
			var resourceId = $this.attr("resourceid");
			/*
			if (typeof _gaq != "undefined") {
				_gaq.push(["_trackEvent", "Links", "Click", href]);
			}
			if (typeof ga != "undefined") {
				ga("send", "event", {
					eventCategory: "Links",
					eventAction: "Click",
					eventLabel: href,
					dimension1: localeId,
					dimension2: gaDimensions["dimension2"],
					dimension3: gaDimensions["dimension3"],
					dimension4: resourceId,
				});
			}
			*/
			if (typeof gtag != "undefined") {
				gtag("event", "click", {
					link_url: href,
					locale: localeId,
					county_id: ga4Dimensions["county_id"],
					county_name: ga4Dimensions["county_name"],
					resource_id: resourceId,
				});
			}
			if (target.length == 0) {
				setTimeout(function () {
					location.href = href;
				}, 200);
				return false;
			}
		});
		$("audio.audio-player").on("play", function (e) {
			var $this = $(this);
			var href = $this.attr("src").split("?")[0];
			var localeId = $this.attr("localeid").toUpperCase();
			var resourceId = $this.attr("resourceid");
			/*
			if (typeof _gaq != "undefined") {
				_gaq.push(["_trackEvent", "Downloads", "MP3", href]);
			}
			if (typeof ga != "undefined") {
				ga("send", "event", {
					eventCategory: "Downloads",
					eventAction: "MP3",
					eventLabel: href,
					dimension1: localeId,
					dimension2: gaDimensions["dimension2"],
					dimension3: gaDimensions["dimension3"],
					dimension4: resourceId,
				});
			}
			*/
			if (typeof gtag != "undefined") {
				gtag("event", "audio_play", {
					link_url: href,
					locale: localeId,
					county_id: ga4Dimensions["county_id"],
					county_name: ga4Dimensions["county_name"],
					resource_id: resourceId,
				});
			}
			$this.off("play");
		});
		$("a")
			.not(
				"a.amp_modal, .download-link, .external-link, .nav-tabs a, .pagination a, .accordion-nav a, .breadcrumbs a, .sidebar a, .topic-list a, .subtopic-list a, .header a, .topic-subtopic-nav a"
			)
			.on("click", function (e) {
				var $this = $(this);
				var hostName = $this[0].hostname;
				var pathName = $this[0].pathname;
				var fullHref = $this.attr("href");
				var href = $this.attr("href").split("?")[0];
				var target = $this.attr("target") ? $this.attr("target") : "";
				var fileType = $this.attr("filetype") ? $this.attr("filetype") : "";
				var localeId = $this.attr("localeid") ? $this.attr("localeid").toUpperCase() : "";
				var resourceId = $this.attr("resourceid") ? $this.attr("resourceid") : "";
				var isPDF = pathName.substring(pathName.lastIndexOf(".") + 1).toUpperCase() == "PDF" ? true : false;
				if (isPDF) fileType = "PDF";
				var isExternal = location.hostname != hostName;
				if (isPDF || isExternal) {
					if (isPDF) {
						/*
						if (typeof _gaq != "undefined") {
							_gaq.push(["_trackEvent", "Downloads", fileType, href]);
						}
						if (typeof ga != "undefined") {
							var eventObject = {
								eventCategory: "Downloads",
								eventAction: fileType,
								eventLabel: href,
								dimension2: gaDimensions["dimension2"],
								dimension3: gaDimensions["dimension3"],
							};
							if (localeId.length) eventObject["dimension1"] = localeId;
							if (resourceId.length) eventObject["dimension4"] = resourceId;
							ga("send", "event", eventObject);
						}
						*/
						if (typeof gtag != "undefined") {
							gtag("event", "file_download", {
								file_extension: fileType,
								link_url: href,
								locale: localeId,
								county_id: ga4Dimensions["county_id"],
								county_name: ga4Dimensions["county_name"],
								resource_id: resourceId,
							});
						}
					} else {
						/*
						if (typeof _gaq != "undefined") {
							_gaq.push(["_trackEvent", "Links", "Click", href]);
						}
						if (typeof ga != "undefined") {
							var eventObject = {
								eventCategory: "Links",
								eventAction: "Click",
								eventLabel: href,
								dimension2: gaDimensions["dimension2"],
								dimension3: gaDimensions["dimension3"],
							};
							if (localeId.length) eventObject["dimension1"] = localeId;
							if (resourceId.length) eventObject["dimension4"] = resourceId;
							ga("send", "event", eventObject);
						}
						*/
						if (typeof gtag != "undefined") {
							gtag("event", "click", {
								link_url: href,
								locale: localeId,
								county_id: ga4Dimensions["county_id"],
								county_name: ga4Dimensions["county_name"],
								resource_id: resourceId,
							});
						}
					}
				}
				if (target.length == 0) {
					setTimeout(function () {
						location.href = fullHref;
					}, 200);
					return false;
				}
			});
	}
});
